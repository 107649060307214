import PropTypes from 'prop-types'
import styles from './index.module.less'

export default function Index ({ children, ...rest }) {
  return (
    <div className={styles.container} {...rest}>
      {children}
    </div>
  )
}

Index.propTypes = {
  children: PropTypes.any
}
