import ViewContainer from './container'
import { Image } from 'antd'
import styled from 'styled-components'

const StyledCard = styled.div`
  text-align: center;
  background-image: linear-gradient(rgba(255,255,255, 0.9), rgba(255,255,255, 0.6)), url('/images/supercard/sc-bg-gold.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 20px;

`

export default function Index () {
  return (
    <ViewContainer>
      {() => (
        <StyledCard>
          <Image src='/images/supercard/mr-peri.png' alt="Peri-peri" width='280' height='280' preview={false} />
        </StyledCard>
      )}
    </ViewContainer>
  )
}
