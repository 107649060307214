import { useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'

export const useHasSuperCard = () => {
  const state = useStoreState(s => ({ scCardsV2: s.scCardsV2, me: s.me, latestActiveSc: s.latestActiveSc }))

  const [hasSc, setHasSc] = useState(false)

  useEffect(() => {
    const { status, payload } = state.scCardsV2
    if (status === 2) {
      const { Cards } = payload
      const hasActiveCards = Cards.filter(card => card.Status === 'Active')

      setHasSc(Cards.length > 0 && hasActiveCards.length > 0)
    } else {
      setHasSc(false)
    }
  }, [state.scCardsV2])

  useEffect(() => {
    sessionStorage.setItem('hasSuperCard', hasSc)
  }, [hasSc])

  return !state.me.payload?.details ? false : hasSc
}
