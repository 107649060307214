import { getBaseUrl } from 'utils/url'
import Sentry from './sentry'

export const userAuth = async (req) => {
  try {
    const baseUrl = getBaseUrl(req)
    const response = await fetch(`${baseUrl}/api/auth/me`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
    })
    const json = await response.json()
    if (response.ok) {
      return json
    }
    throw json
  } catch (error) {
    console.error('#### ERROR --- ', error)
    if (process.env.STAGE) {
      Sentry.captureException(error, {
        tags: {
          section: process.env.STAGE
        }
      })
    }
    return null
  }
}

export const hasSuperCard = () => {
  if (typeof window !== 'undefined') {
    const sessionHasSC = JSON.parse(sessionStorage.getItem('hasSuperCard')) === true || undefined

    if (sessionHasSC) {
      return true
    } else {
      return false
    }
  }
}

// export const hasSuperCard = state => {
//   const me = (state.me.status === 2 && state.me.payload.details) && state.me.payload.details
//   const sc = me && ((me && me.has_supercard) || (me.sc_expiration && !moment().isAfter(me.sc_expiration)))

//   return sc
// }
