import PropTypes from 'prop-types'
import { StyledModal } from './Default/index'
import { StyledProduct } from './Product/index'
import { StyledOrderTracker } from './OrderTracker/index'
import { StyledPeriModal } from './styles'
import { Row, Col, Button, Spin } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useRouter } from 'next/router'

// Default Modal
export const DefaultModal = ({ children, visible, ...props }) => {
  return (
    <StyledModal {...props} visible={visible}>
      <div>{children}</div>
    </StyledModal>
  )
}

DefaultModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any
}
// End of Default Modal

// Product Modal
export const ProductModal = ({ children, visible, ...props }) => {
  return (
    <StyledProduct {...props} visible={visible}>
      <div>{children}</div>
    </StyledProduct>
  )
}

ProductModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any
}

// End of Product Modal

// Order Tracker Modal
export const OrderTrackerModal = ({ children, visible, ...props }) => {
  return (
    <StyledOrderTracker {...props} visible={visible}>
      <div>{children}</div>
    </StyledOrderTracker>
  )
}

OrderTrackerModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any
}

// End of Product Modal

// peri design modal

export const AccountSharingModal = ({ children, isCreateAccount = false, agreeToShare, ...props }) => {
  const router = useRouter()

  const states = useStoreState(s => ({ loginStateV2: s.loginStateV2 }))
  const actions = useStoreActions(a => ({ logout: a.logout, setAccSharingModalVisible: a.setAccSharingModalVisible, setRegisterStateV2: a.setRegisterStateV2, setGoogleConvert: a.setGoogleConvert, setFacebookConvert: a.setFacebookConvert }))

  const handleRedirect = () => {
    if (!isCreateAccount) {
      sessionStorage.removeItem('creds')
    }

    actions.setAccSharingModalVisible(false)
    actions.setRegisterStateV2({ status: 0, payload: null })
    actions.setGoogleConvert({ status: 0, payload: null })
    actions.setFacebookConvert({ status: 0, payload: null })
    router.push('/auth/register')
  }

  return (
    <StyledPeriModal footer={null} centered closable={false} width={509} visible={true} {...props}>
      <div>
        <img
        className='mr-peri-modal'
          alt='MR. PERI'
          draggable='false'
          src='/images/auth-assets/mr-peri.png'
        />
        <Spin spinning={states.loginStateV2.status === 1}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <h1 className='text-orange-peel text-center'>Almost there!</h1>
              <p className='text-grey text-center'>Our system detected that the e-mail you nominated has a Shakey&apos;s Account.</p>
            </Col>
            <Col span={24}>
              <p className='text-grey text-center'>Would you like to use your Shakey&apos;s account with the Peri-Peri website?</p>
            </Col>
            <Col span={24}>
              <Button block type='primary' onClick={agreeToShare}>
                {isCreateAccount ? 'Proceed to Login' : 'Okay'}
              </Button>
            </Col>
            <Col span={24}>
              <Button block className='text-black' style={{ fontSize: 18 }} type='link' onClick={handleRedirect}>
                No, I'd like to use a different e-mail account
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    </StyledPeriModal>
  )
}

AccountSharingModal.propTypes = {
  children: PropTypes.any,
  isCreateAccount: PropTypes.bool,
  agreeToShare: PropTypes.func
}

// updated legal terms prompt
export const UpdatedLegalTerms = ({ visible, setVisible, handleAccept, ...props }) => {
  const router = useRouter()

  const state = useStoreState(s => ({ updatedAcceptedTerms: s.updatedAcceptedTerms }))

  const handleRedirect = (path) => {
    router.push(path)
  }

  return (
    <StyledPeriModal visible={visible} footer={null} centered closable={false} width={509} {...props}>
      <Spin spinning={state.updatedAcceptedTerms.status === 1}>
        <Row justify='center' gutter={[16, 24]}>
          <Col span={24}>
            <img
          alt='MR. PERI'
          draggable='false'
          src='/images/order-tracker/store.png'
          style={{ width: '100%' }}
        />
          </Col>
          <Col span={24}>
            <h3 className='text-orange-peel text-center' style={{ lineHeight: 'normal' }}>We've updated our Terms!</h3>
          </Col>
          <Col span={24}>
            <p className='text-grey text-center' style={{ fontSize: 16 }}>To continue ordering, you need to confirm that you agree to our Terms and Conditions and have read our updated Privacy Policy.</p>
          </Col>
          <Col span={24}>
            <Button style={{ fontSize: 16 }} onClick={() => handleRedirect('/terms-and-conditions')} block type='link'>View Terms and Conditions</Button>
            <Button style={{ fontSize: 16 }} onClick={() => handleRedirect('/privacy-policy')} block type='link'>View Terms Privacy Policy</Button>
            <Button block type='primary' onClick={handleAccept}>Agree</Button>
          </Col>
        </Row>
      </Spin>
    </StyledPeriModal>
  )
}

UpdatedLegalTerms.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired
}
