import { Modal } from 'antd'
import styled from 'styled-components'
export const Styled = styled(Modal)`
  .ant-modal-body {
    min-height: 500px;
  }
  .ant-modal-close-x {
    color: var(--orange-peel);
    font-size: 32px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`
