import { Modal } from 'antd'
import styled from 'styled-components'
export const Styled = styled(Modal)`

  .ant-modal-body {
    min-height: 500px;
    .ant-card {
      background-image: url('/images/supercard/sc-bg-default.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`
