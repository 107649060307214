import PropTypes from 'prop-types'
import { Styled } from './styles'
import ChickenHeader from 'components/Images/ChickenHeader'

// Styled List
export const StyledProduct = ({ children, setVisible, visible, width = 676, maskClosable = false, ...rest }) => {
  return (
    <Styled
      width={width}
      bodyStyle={{ padding: '0' }}
      closable={rest.closable ? rest.closable : false }
      footer={null}
      style={{ top: 164 }}
      visible={visible}
      maskClosable={maskClosable}
      onCancel={() => setVisible(false)}
      {...rest}
    >
      <ChickenHeader />
      {children}
    </Styled>
  )
}

StyledProduct.propTypes = {
  width: PropTypes.number,
  setVisible: PropTypes.any,
  visible: PropTypes.bool,
  children: PropTypes.any,
  maskClosable: PropTypes.bool
}
