import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledPeriModal = styled(Modal)`
  .ant-modal-body,
  .ant-modal-content {
    border-radius: 15px;
    padding: 16px 36px;
    background-image: url('/images/bg/catalog-bg.png');
    p {
      width: 100%;
    }
    .mr-peri-modal {
      width: 167px;
      object-fit: contain;
      position: absolute;
      left: -105px;
      bottom: 0;
    }
  }
`
