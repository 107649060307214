// import PropTypes from 'prop-types'
import MediumContainer from 'components/MediumContainer'
import Link from 'next/link'
import WideContainer from 'components/WideContainer'
import { Row, Col, Button } from 'antd'
import {
  StyledTopBanner,
  StyledSauceImage,
  StyledMrPeri,
  StyledTopBannerContent,
  Styled3,
  Styled4,
  Styled5
} from './styles'
import { useState, useEffect } from 'react'

export const TopBanner = () => {
  const [currentBg, setCurrentBg] = useState(0)

  const backgroundImages = [
    '/images/HeaderBanner.png',
    '/images/peri-ribs.png'
  ]

  useEffect(() => {
    const bgInterval = setInterval(() => {
      currentBg !== backgroundImages.length - 1 ? setCurrentBg(prev => prev + 1) : setCurrentBg(prev => prev - 1)
    }, 3000)

    return () => { clearInterval(bgInterval) }
  }, [currentBg])

  return (
    <StyledTopBanner bg={backgroundImages[currentBg]}>
      <StyledSauceImage src='/images/Sauce.png' alt='' />
      <StyledTopBannerContent>
        <MediumContainer>
          <Row>
            <Col
              xl={{ span: 8, offset: 8 }}
              lg={{ span: 8, offset: 8 }}
              md={{ span: 8, offset: 8 }}
              sm={{ span: 8, offset: 8 }}
              xs={24}
              className='promo-link'
              align='center'
            >
              <h1 style={{ color: 'var(--cream)' }}>See Promos</h1>
              <Link href='/promos'>
                <Button className='cognac-btn'>Click Here</Button>
              </Link>
            </Col>
            <Col
              xl={{ span: 8, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              md={0}
              sm={0}
              xs={0}
              className='chili-image'
              align='right'
            >
              <img className='banner-2' src='/images/Chili Vector.png' alt='' />
            </Col>
          </Row>
        </MediumContainer>
      </StyledTopBannerContent>
    </StyledTopBanner>
  )
}

export const MrPeriImage = () => (
  <StyledMrPeri>
    <img src='/images/MR PERI.png' alt='' />
  </StyledMrPeri>
)

export const Banner3 = () => {
  return (
    <Styled3>
      <Col
        xl={{ span: 8, offset: 1 }}
        lg={{ span: 8, offset: 1 }}
        md={{ span: 8, offset: 1 }}
        sm={{ span: 24, offset: 1 }}
        xs={{ span: 24, offset: 1 }}
        style={{ paddingTop: '50px' }}
        // span={8} offset={1} style={{ paddingTop: '50px' }}
      >
        <img
          style={{ width: 400, maxWidth: '100%', marginBottom: '-50px' }}
          src='/images/supercard/v2/sc-classic-gold.png'
          alt=''
        />
      </Col>
      <Col
        xl={{ span: 7 }}
        lg={{ span: 7 }}
        md={{ span: 7 }}
        sm={{ span: 24, offset: 1 }}
        xs={{ span: 24, offset: 1 }}
        style={{ paddingTop: '80px' }}
        // span={7}
      >
        <h1>More</h1>
        <img
          style={{ maxWidth: '100%' }}
          src='/images/supercard-benefits-text.png'
          alt=''
        />
        <h1>Just for you.</h1>
        <Link href='/supercard/buy'>
          <Button type='primary' style={{ width: '220px', marginTop: '50px' }}>
            Learn More
          </Button>
        </Link>
      </Col>
      <Col xl={{ span: 7 }} lg={{ span: 7 }} md={{ span: 7 }} sm={0} xs={0}>
        <img className='banner-1' src='/images/ROPE.png' alt='' />
      </Col>
    </Styled3>
  )
}
export const Banner4 = () => {
  return (
    <Styled4>
      <img className='mascot' src='/images/peri-sauce-bar.png' alt='' />
      <Col span={24}>
        <img
          style={{ margin: 'auto', display: 'block', maxWidth: '100%' }}
          src='/images/logos/peri-peri-text.png'
          alt=''
        />
        <img
          style={{ margin: 'auto', display: 'block', maxWidth: '100%' }}
          src='/images/Sauce BAR.png'
          alt=''
        />
        <img style={{ width: '100%' }} src='/images/Bottles.png' alt='' />
        <Link href='/catalog/categories/sauces'>
          <Button className='cognac-btn'>Order Now</Button>
        </Link>
      </Col>
    </Styled4>
  )
}

export const DeliveryBanner = () => {
  return (
    <Styled5>
      <WideContainer>
        <img src='/images/DeliveryBanner.png' alt='' />
      </WideContainer>
    </Styled5>
  )
}
